<template>
  <div class="navbar">
    <hamburger id="hamburger-container" :is-active="appStore.sidebar.opened" class="hamburger-container"
      @toggleClick="toggleSideBar" />
    <breadcrumb id="breadcrumb-container" class="breadcrumb-container" v-if="!settingsStore.topNav" />
    <top-nav id="topmenu-container" class="topmenu-container" v-if="settingsStore.topNav" />

    <div class="right-menu">
      <template v-if="appStore.device !== 'mobile'">
        <!-- <header-search id="header-search" class="right-menu-item" />

        <el-tooltip content="文档地址" effect="dark" placement="bottom">
          <ruo-yi-doc id="ruoyi-doc" class="right-menu-item hover-effect" />
        </el-tooltip>

        <screenfull id="screenfull" class="right-menu-item hover-effect" /> -->
        <template v-if="userStore.roles[0] == 'common'">
          <div class="message">
            <span class="account">账户余额</span>
            <span class="money">￥{{ userStore.money }}</span>
          </div>
          <div class="message">
            <el-button type="primary" @click="turn('/finance/recharge')">充值</el-button>
          </div>
          <div class="message m2">
            <div class="item_msg" @click="wxChat">
              <img src="@/assets/logo/customer.svg" alt="">
              <div class="mt">在线客服</div>
            </div>
          </div>
          <div class="message m2" @click="forward">
            <el-badge v-if="userStore.unreadCount" :value="userStore.unreadCount" :max="99" class="item_msg">
              <img src="@/assets/logo/message.svg" alt="">
              <div class="mt">我的消息</div>
            </el-badge>
            <div v-else class="item_msg">
              <img src="@/assets/logo/message.svg" alt="">
              <div class="mt">我的消息</div>
            </div>
          </div>
        </template>


      </template>
      <div class="avatar-container">
        <el-dropdown @command="handleCommand" class="right-menu-item hover-effect" trigger="click">
          <div class="avatar-wrapper">
            <img src="@/assets/logo/avatar.svg" class="user-avatar" />
            <el-icon><caret-bottom /></el-icon>
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <div class="dropbox">
                <div class="ava p20 df">
                  <img src="@/assets/logo/avatar.svg" />
                  <span style="font-weight: bold;font-size: 22px;margin-right: 12px;">欢迎!</span>
                  <span style="width: 190px;">{{ userStore.phone || userStore.email || userStore.name }}</span>
                </div>
                <template v-if="userStore.roles[0] == 'common'">

                  <div class="ava p20 amo">
                    <div class="avat">账户总览</div>
                    <div class="amoney df">
                      <span style="margin-right: 4px;">余额：</span>
                      <span style="color: #FFB30A;margin-right: 24px;">￥{{ userStore.money }}</span>
                      <el-button color="#FFB30A" plain @click="turn('/finance/recharge')">充值</el-button>
                    </div>
                  </div>
                  <div class="ava amo p10">
                    <el-badge v-if="userStore.unreadCount" is-dot :offset="[20, 19]">
                      <div class="ava-each" @click="turn('/other/message')">我的消息</div>
                    </el-badge>
                    <div class="ava-each" v-else @click="turn('/other/message')">我的消息</div>
                    <div class="ava-each" @click="turn('/finance/order')">充值记录</div>
                    <div class="ava-each" @click="turn('/extension/extension')">我的推广</div>
                  </div>
                </template>
                <div class="logt" @click="logout">退出</div>


              </div>

            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  
  </div>
</template>

<script setup>
import { ElMessageBox } from 'element-plus'
import Breadcrumb from '@/components/Breadcrumb'
import TopNav from '@/components/TopNav'
import Hamburger from '@/components/Hamburger'
import Screenfull from '@/components/Screenfull'
import SizeSelect from '@/components/SizeSelect'
import HeaderSearch from '@/components/HeaderSearch'
import RuoYiGit from '@/components/RuoYi/Git'
import RuoYiDoc from '@/components/RuoYi/Doc'
import useAppStore from '@/store/modules/app'
import useUserStore from '@/store/modules/user'
import useSettingsStore from '@/store/modules/settings'

const appStore = useAppStore()
const userStore = useUserStore()
const settingsStore = useSettingsStore()
const router = useRouter();


function forward() {
  router.push("/other/message")
}

function turn(url) {
  router.push(url)
}


function toggleSideBar() {
  appStore.toggleSideBar()
}

function handleCommand(command) {
  switch (command) {
    case "setLayout":
      setLayout();
      break;
    case "logout":
      logout();
      break;
    default:
      break;
  }
}

function logout() {
  ElMessageBox.confirm('确定注销并退出系统吗？', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(() => {
    userStore.logOut().then(() => {
      // location.href = '/index';
      // location.href = "http://192.168.31.148:3333/login";

      location.href = "https://margoproxy.cn/login?redirect=1";
    })
  }).catch(() => { });
}

const emits = defineEmits(['setLayout'])
function setLayout() {
  emits('setLayout');
}

function wxChat() {
  window.open('https://work.weixin.qq.com/kfid/kfca49d3b80770e9066')
}
console.log('%c [ 4444 ]', 'font-size:13px; background:pink; color:#bf2c9f;', 4444)
</script>

<style lang='scss' scoped>
.navbar {
  height: 70px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .hamburger-container {
    line-height: 70px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background-color: rgba(233, 242, 234, 0.4);

    }
  }

  .breadcrumb-container {
    float: left;
  }

  .topmenu-container {
    position: absolute;
    left: 50px;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;
    // line-height: 70px;
    display: flex;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;
        display: flex;
        align-items: center;


        &:hover {
          background-color: rgba(233, 242, 234, 0.4);

        }
      }
    }

    .avatar-container {
      margin-right: 40px;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }

        i {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 50%;
          transform: translateY(-50%);
          font-size: 12px;
        }
      }
    }
  }
}

.m2 {
  &:hover {
    background-color: rgba(233, 242, 234, 0.4);
  }

}

.message {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 27px;
  padding: 0 8px;


  .item_msg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 6px;

    img {
      height: 24px;
      margin-bottom: 4px;
    }

    .mt {
      font-size: 14px;
      color: #2E3E31;
    }

  }

  .account {
    font-weight: 400;
    font-size: 18px;
    color: #2E3E31;
    margin-right: 16px;
  }

  .money {
    font-weight: bold;
    font-size: 20px;
    color: #2E3E31;
  }

}

:deep(.el-badge__content.is-fixed) {
  right: 28px !important;
}

.dropbox {
  width: 366px;
  // height: 410px;
  background: #FFFFFF;

  .p20 {
    padding: 20px;
  }

  .ava {
    border-bottom: 1px solid #ECECEC;

    img {
      width: 44px;
      height: 44px;
      margin-right: 24px;
    }

    span {
      font-size: 16px;
      color: #2E3E31;
    }
  }

  .amo {
    display: flex;
    flex-direction: column;

    .avat {
      font-weight: bold;
      font-size: 20px;
      color: #2E3E31;
      margin-bottom: 12px;
    }

    .amoney {
      span {
        font-size: 18px;
        color: rgba(46, 62, 49, 0.8);
      }
    }
  }

  .p10 {
    padding: 10px;
  }

  .ava-each {
    // margin-bottom: 20px;
    font-size: 18px;
    color: rgba(46, 62, 49, 0.8);
    cursor: pointer;
    padding: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      background-color: #ebf4ec;
      color: #6ECA7F;

    }


  }
}

.logt {
  font-size: 18px;
  color: rgba(46, 62, 49, 0.8);
  cursor: pointer;
  padding: 20px;
}

.df {
  display: flex;
  align-items: center;
}



</style>
